import React from 'react';
import { UsagePage } from '@mfe/features/usage';
import { useSelector } from 'react-redux';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { Spinner, colors } from '@vst/beam';
import { useRouter } from 'next/router';

const Usage = (): JSX.Element | null => {
  const {
    user: {
      auth: {
        tokenInfo: { accessToken },
      },
    },
  } = useSelector(selectUser);

  const router = useRouter();

  const [isChangePlanPageLoading, setIsChangePlanPageLoading] =
    React.useState(false);

  if (isChangePlanPageLoading) {
    /*
     * Given that we're using the Next.js `router` and
     * the routing from Overview to ACP is done inside an
     * iframe for the mobile apps,
     * this loader gives the user some feedback that
     * something is happening while the routing is happening
     */
    return (
      <div
        style={{
          padding: '56px 0',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: colors.white,
        }}
      >
        <Spinner />
      </div>
    );
  }

  const navigateToChangePlan = () => {
    setIsChangePlanPageLoading(true);
    router.push(`/ChangePlan?token=${accessToken}`);
  };

  const navigateToPage = (link: string) => {
    router.push(link);
  };

  return (
    <UsagePage
      navigateToChangePlan={navigateToChangePlan}
      navigateToPage={navigateToPage}
    />
  );
};

export default Usage;
